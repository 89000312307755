<template>
    <div class="container-fluid">
        <div class="row" v-if="! showPayButton">

            <div class="col-lg-12">
                <hr class="my-4"/>
                <h3 class="mb-0 text-uppercase">Student Identification Information</h3>
                <hr>
            </div>

            <div class="col-lg-3">
                <base-input alternative=""
                    label="Expected Graduation Year"
                    required
                >
                    <select
                        class="form-control form-control-alternative"
                        v-model="record.grad_expected_year"
                        :disabled="hasValidInsurance"
                    >
                        <option :value="null" disabled selected>--- Select One ---</option>
                        <option 
                            v-for="year in years"
                            :key="year"
                            :value="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </base-input>
            </div>

            <div class="col-lg-3">
                <base-input alternative=""
                    label="School ID Number"
                    placeholder="Enter Identity Number"
                    input-classes="form-control-alternative"
                    v-model="record.identity_no"
                    type="text"
                    required
                    :readonly="hasValidInsurance"
                />
            </div>

            <div class="col-lg-3">
                <base-input
                    label="School ID Issue Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required
                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: false, maxDate: 'today'}"
                        class="form-control datepicker"
                        v-model="record.issue_date"
                        :disabled="hasValidInsurance"
                    >
                    </flat-picker>
                </base-input>
            </div>

            <div class="col-lg-3">
                <base-input
                    label="School ID Expiration Date"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: false}"
                        class="form-control datepicker"
                        v-model="record.id_expiry_date"
                        :disabled="hasValidInsurance"
                    >
                    </flat-picker>
                </base-input>
            </div>

        
            <div class="col-lg-12">
                <hr class="my-4"/>
                <h3 class="mb-0 text-uppercase">Beneficiary (Next of Kin) Information</h3>
                <hr>
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="First Name"
                    placeholder="Enter First Name"
                    input-classes="form-control-alternative"
                    v-model="record.first_name"
                    type="text"
                    required
                    :readonly="hasValidInsurance"
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Last Name"
                    placeholder="Enter Last Name"
                    input-classes="form-control-alternative"
                    v-model="record.last_name"
                    type="text"
                    required
                    :readonly="hasValidInsurance"
                />
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Email Address"
                    placeholder="Enter Email Address"
                    input-classes="form-control-alternative"
                    v-model="record.email"
                    type="email"
                    required
                    :readonly="hasValidInsurance"
                />
            </div>

            <div class="col-lg-4">
                <base-input
                    label="Date of Birth"
                    addon-left-icon="ni ni-calendar-grid-58"
                    required

                >
                    <flat-picker
                        slot-scope="{focus, blur}"
                        @on-open="focus"
                        @on-close="blur"
                        :config="{allowInput: false, maxDate: 'today'}"
                        class="form-control datepicker"
                        v-model="record.date_of_birth"
                        :disabled="hasValidInsurance"
                    >
                    </flat-picker>
                </base-input>
            </div>
            
            <div class="col-lg-4">
                <base-input alternative=""
                    label="Phone Number"
                    input-classes="form-control-alternative"
                    required
                >
                <!-- v-model="profile.phone"  -->
                    <VuePhoneNumberInput
                        v-model="tel.phone"
                        @update="updatePhoneNumber"
                        :default-country-code="tel.code"
                        :no-example="false"
                        :clearable="true"
                        :disabled="hasValidInsurance"
                    />
                </base-input>
            </div>

            <div class="col-lg-4">
                <base-input alternative=""
                    label="Relationship"
                    placeholder="Enter Relationship"
                    input-classes="form-control-alternative"
                    v-model="record.relationship"
                    required
                    :readonly="hasValidInsurance"
                />
            </div>

            <div class="col-lg-12">
                <base-input alternative=""
                    label="Contact Address"
                    required
                >
                    <textarea
                        rows="2"
                        class="form-control form-control-alternative"
                        placeholder="Enter Contact Address"
                        v-model="record.address"
                        :readonly="hasValidInsurance"
                    ></textarea>
                </base-input>
            </div>

            <div class="col-lg-12">
                <base-alert :type="errorType" dismissible v-if='showInputErrorStatus'>
                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                    <span class="alert-inner--text">
                        {{errorMsg}}
                    </span>
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </base-alert>
            </div>

            <div class="col-lg-12">
                <hr class="my-4"/>
            </div>

            <div class="col-lg-12">
                <base-button
                    type="success"
                    @click="createAccount()"
                    v-if="! showPayButton"
                >
                    <i class="fa fa-save"></i>
                    {{isloading ? 'Please Wait...' : 'Update Profile Now'}}
                </base-button>
            </div>   
        </div>



        <div class="row" v-if="showPayButton">
            <div class="col-lg-12">
                <div class="d-flex justify-content-center">
                    <img :src="completeImageUrl" />
                </div>
                <br>
                <h2 class="text-uppercase text-center">
                    Your Insurance was created Successfully, click on the button below to make payment now...
                </h2>
                <h4 class="text-uppercase text-center text-danger">
                    Note: You have to pay for insurance before you can proceed with your application...
                </h4>
                <hr>
                <div class="text-center">
                    <paystack
                        :amount="paystackInfo.amount"
                        :email="paystackInfo.email"
                        :paystackkey="paystackInfo.paystackkey"
                        :reference="trans_no"
                        :subaccount="paystackSubaccount"
                        :callback="callback"
                        :close="close"
                        :embed="false"
                        class="btn btn-success"
                        id="paystack"
                        v-if="showPayButton"
                    >
                        <i class="fa fa-credit-card"></i> Make Payment
                    </paystack>
                </div>
                <br><br>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";  
    import moment from 'moment';  
    import swal from 'sweetalert2';// import sweet alert
    import paystack from "vue-paystack";

    export default {
        components: {
            VuePhoneNumberInput,
            flatPicker,
            paystack
        },
        name: 'InsurancePurchaseForm',
        props: ['data', 'hasValidInsurance', 'paystackInfo', 'callback', 'close', 'loadInsuranceRecords', 'loadStudentInfo'],
        data() {
            return {
                record: {
                    grad_expected_year: null,
                    identity_no: null,
                    issue_date: null,
                    id_expiry_date: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    date_of_birth: null,
                    phone: null,
                    relationship: null,
                    address: null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                showInputErrorStatus: false,
                tel: {
                    phone: null,
                    code: "NG"
                },
                trans_no: null,
                showPayButton: false
            };
        },
        computed: {
            ...mapGetters(['paystackSubaccount', 'completeImageUrl']),
            hasInsuranceAccount() {// check if student has insurance account
                return this.student && (this.student.insurance_account == 'yes');
            },
            years() {
                let startYear = 2019;// start from 1900
                let currentYear = parseInt(moment().format('YYYY')) + 20;
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            student() {
                return this.data && this.data ? this.data.student : {};
            },
            parameters() {// insurance input fields
                return {
                    action: "New",
                    title: (this.student.title ? this.titleCase(this.student.title) : ''),
                    first_name: (this.student.first_name ? this.titleCase(this.student.first_name) : ''),
                    last_name: (this.student.last_name ? this.titleCase(this.student.last_name) : ''),
                    gender: (this.student.gender && (this.student.gender == 'male') ? 'M' : 'F'),
                    marital_status: (this.student.marital_status ? this.titleCase(this.student.marital_status) : ''),
                    dob: (this.student.date_of_birth ? this.student.date_of_birth : ''),
                    phone_no: (this.student.phone ? this.student.phone : ''),
                    email: (this.student.email ? this.student.email : ''),
                    contact_address: (this.student.contact_address ? this.student.contact_address : ''),
                    town: (this.student.town ? this.student.town : ''),
                    state: (this.student.state ? this.student.state : ''),
                    year_of_admission: (this.student.admission_year ? this.student.admission_year.toString() : ''),
                    current_level: (this.student.level ? this.student.level : ''),
                    grad_expected_year: (this.record.grad_expected_year ? this.record.grad_expected_year.toString() : ''),
                    means_of_id: "school ID",
                    id_no: (this.record.identity_no ? this.record.identity_no : ''),
                    date_of_issue: (this.record.issue_date ? this.record.issue_date : ''),
                    date_of_expiration: (this.record.id_expiry_date ? this.record.id_expiry_date : ''),
                    nok_first_name: (this.record.first_name ? this.titleCase(this.record.first_name) : ''),
                    nok_last_name: (this.record.last_name ? this.titleCase(this.record.last_name) : ''),
                    relationship: (this.record.relationship ? this.record.relationship : ''),  
                    nok_address: (this.record.address ? this.record.address : ''),  
                    nok_phone_no: (this.record.phone ? this.record.phone : ''),  
                    nok_email: (this.record.email ? this.record.email : ''),
                    nok_dob: (this.record.date_of_birth ? this.record.date_of_birth : ''),
                    institution_name: (this.student.school && this.student.school.school_name ? this.student.school.school_name : ''),
                }
            }
        },
        methods: {
            ...mapActions(['createStudentInsurance']),
            titleCase(string) {
                return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                }
            },
            emptyFields() {
                return this.record = {
                    grad_expected_year: null,
                    identity_no: null,
                    issue_date: null,
                    id_expiry_date: null,
                    first_name: null,
                    last_name: null,
                    email: null,
                    date_of_birth: null,
                    phone: null,
                    relationship: null,
                    address: null,
                    level: (this.student.level ? this.student.level : null)
                };                
            },
            createAccount() {
                let res = this.validInputs();
                if(res.status) {// update
                    this.showPayButton = false;
                    this.isloading = true;
                    this.trans_no = null;
                    this.preloader();
                    let data = { inputs : res.data, id : this.id, student_id: this.student, parameters : this.parameters };
                    this.createStudentInsurance(data).then((res) => {
                        this.isloading = false;
                        if(res.status && res.insurance) {
                            this.trans_no = res.insurance.trans_no;
                            this.showPayButton = true;
                            this.emptyFields();
                            swal.fire("Insurance Profile Updated!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }
            },
            validInputs() {
                let validItems = {};
                let inputs = this.record;
                const inputsArray = Object.entries(inputs);
                for(let item of inputsArray) { 
                    const input = item[0];
                    const value = item[1];
                    const isValid = (value != null && value != '');// check for empty input
                    if(isValid) {
                        validItems[input] = value;
                    } else {
                        const errorText = (input == 'world_school_id') ? 'University' : this.replaceAll(input, '_', ' ');
                        this.showInuptError(errorText);
                        validItems = {};
                        break;
                    }
                }
                var status = Object.entries(validItems).length > 0;
                return {data : validItems, status : status };                
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'), replace);
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + this.titleCase(name) + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.emptyFields();// empty fields
        }
    }
</script>

<style scoped>
    .float-right {
        float: right;
    }
</style>