<template>
    <div>
        <VerificationRrr 
            :url="'/new/application/form'"
        />
    </div>
</template>

<script>
    import VerificationRrr from '../../../Verification/VerificationRrr.vue' 
    export default {
        name: 'NewIndexingRrr',
        components: {
            VerificationRrr
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>