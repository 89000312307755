<template>
    <div>
        <VerificationGenerate />
    </div>
</template>

<script>
    import VerificationGenerate from '../../../Verification/VerificationGenerate.vue' 
    export default {
        name: 'NewIndexingGenerate',
        components: {
            VerificationGenerate
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>