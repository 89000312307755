<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">
                                        {{ policy && policy.title ? policy.title : this.$route.name }}
                                    </h3>
                                    <br>
                                </div>
                                <div class="col-4 text-right">
                                    <a :href="websiteUrl" class="btn btn-sm btn-danger">
                                       <i class="fa fa-reply"></i> Home
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="! isLoadingBlade">
                            <div class="col-lg-12"
                                v-html="policy.content"
                                v-if="policy"
                            ></div>

                            <div class="col-lg-12">
                                <hr>
                                <a
                                    :href="websiteUrl"
                                    class="btn btn-danger"
                                    :disabled="isLoadingBlade"
                                >
                                    <i class="fa fa-times"></i>
                                    I Disagree
                                </a>

                                <router-link
                                    to="/login"
                                    class="btn btn-success right"
                                    :disabled="isLoadingBlade"
                                >
                                    <i class="fa fa-check"></i>
                                    I Agree
                                </router-link>
                            </div>
                        </div>

                        <div class="row" v-if="isLoadingBlade">
                            <div class="col-lg-12">
                                <div class="d-flex justify-content-center">
                                    <img :src="loadingBladeUrl" />
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'Requirements',
        data() {
            return {
                isLoadingBlade: true,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                policy: null
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl']),
        },
        methods: {
            ...mapActions(['getSiteParameter']),
            getParameter() {// get Parameter
                let name = 'student-policy';
                this.getSiteParameter({'name': name}).then((res) => {
                    this.isLoadingBlade = false;
                    if(res) {
                        this.policy = res;
                    } else {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    }
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.getParameter();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>