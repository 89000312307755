<template>
    <div class="row" v-if="records && records.length > 0">
        <div class="col-lg-12">
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="records"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th>Payee's Name</th>
                            <th>Transaction Date</th>
                            <th>Certificate No</th>
                            <th>Policy No</th>
                            <th>Status</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row}"
                        >
                        <td class="budget">
                            {{ pagination.from}}
                        </td>
                        <td class="text-capitalize">
                            {{ row.student && row.student.full_name ? row.student.full_name : 'N/A' }}
                        </td>
                        <td>
                            {{  row.trans_date | getDateFormat }}
                        </td>
                        <td>
                            {{row.certificate_no ? row.certificate_no : 'N/A'}}
                        </td>
                        <td>
                            {{row.policy_no ? row.policy_no : 'N/A'}}
                        </td>
                        <td>
                            <badge :type="getColor(row.trans_status)">
                                {{ row.trans_status ? row.trans_status : 'N/A' }}
                            </badge>
                        </td>
                        <td>
                            <a
                                :href="row.certificate_url"
                                target="_blank"
                                class="btn btn-success btn-sm text-white"
                                v-if="row.certificate_url"
                            >
                                <i class="fa fa-print"></i>
                                Print Certificate
                            </a>
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: 'InsurancePaymentsData',
        props: ["records", "pagination", "hasPagination", "paginate"],
        filters: {
            getDateFormat(value) {
                if (value) {
                    let split = value.split(' ');
                    let date = split && split.length > 0 ? split[0] : value;
                    return date ? moment(date).format('Do MMM, YYYY') : 'N/A';
                } else {
                    return 'N/A';
                }
            }
        },
        methods: {
            getColor(status) {
                if(status) {
                    return status == 'paid' ? 'success' : 'danger';
                } else {
                    return 'danger';
                }
            }
        },
    }
</script>