<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase">{{ this.$route.name }}</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link to="/" class="btn btn-sm btn-primary">Dashboard</router-link>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div v-if="validIndexingYear && ! isLoadingBlade">
                            <div class="row" v-if="record && record.application_type && record.application_type.has_payment">
                                <div class="col-sm-12 text-center">
                                    <br>
                                    <h3>
                                        <span class="text-uppercase text-danger">{{ record.application_type.name }}</span>
                                        <br><br>
                                        <span>Indexing Year: </span> &nbsp;
                                        <em class="font-weight-light">
                                            {{ indexingYear }}
                                        </em>
                                        <br><br>
                                        <span>Amount: </span> &nbsp;
                                        <em class="font-weight-light">
                                            {{ record.application_type.has_payment.amount | formatPrice }}
                                        </em>

                                        <br><br>
                                        <span>Total Amount: </span> &nbsp;
                                        <em class="font-weight-light">
                                            {{ record.application_type.has_payment.amount | formatPrice }}
                                        </em>
                                    </h3>
                                </div>

                                <div class="col-sm-12 text-center">
                                    <hr>
                                    <router-link
                                        :to="'/new/indexing/application'"
                                        class="btn btn-lg btn-success"
                                    >
                                        <i class="fa fa-share-square"></i> Proceed Now
                                    </router-link>
                                </div>
                            </div>

                            <div class="col-sm-12 text-center">
                                <base-alert :type="errorType" v-if='showErrorStatus'>
                                    <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                                    <span class="alert-inner--text">
                                        {{errorMsg}}
                                    </span>
                                </base-alert>
                            </div>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-sm-12 text-center" v-if="! validIndexingYear && ! isLoadingBlade">
                            <h2 class="text-danger">
                                Your Indexing clearance is up-to-date, kindly wait for the 
                                <em class="text-primary">1st of January of {{ maximunYear }}</em>, <br>
                                to apply for  
                                <em class="text-primary">{{ maximunYear + 1 }}</em>
                                Indexing Application
                                
                            </h2>
                            <br><br>
                            <router-link
                                to="/"
                                class="btn btn-lg btn-primary"
                            >
                                <i class="fa fa-home"></i>
                                Dashboard
                            </router-link>
                        </div>
                        
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions } from 'vuex';
    import moment from 'moment';

    export default {
        name: 'NewApplication',
        filters: {
            formatPrice(value) {
                if(value) {
                    let val = (value/1).toFixed(2).replace(',', '.');
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' NGN';
                } else {
                    return 'N/A';
                }
            }
        },
        data() {
            return {
                record: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl']),
            indexingYear() {
                return this.record && this.record.year ? this.record.year : false;
            },
            maximunYear() {// get maixmum year for license purchase
                return (moment().year() + 1);
            },
            validIndexingYear() {
                return this.indexingYear ? parseInt(this.indexingYear) <= this.maximunYear : false
            }
        },
        methods: {
            ...mapActions(['getStudentIndexingDetails']),
            loadStudentIndexingDetails() {
                this.getStudentIndexingDetails().then((res) => {// get records
                    this.record = res;
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.showErrorStatus = true;
                    this.errorMsg = "Network Failure!, kindly reload the page...";
                    this.errorType = 'danger';
                });
            }
        },
        created() {
            this.loadStudentIndexingDetails();// annual license details
        },
    }
</script>