<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>


        <div class="container-fluid mt--7">
            <div class="row" v-if="! isLoadingBlade && student">

                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">
                    <div class="card card-profile shadow">
                        <div class="row justify-content-center">
                            <div class="col-lg-3 order-lg-2">
                                <div class="card-profile-image">
                                    <a href="javascript:void(0)">
                                        <img
                                            :alt="student.full_name"
                                            :src="studentPhoto"
                                            class="rounded-circle image-size"
                                            @error="replaceByDefault"
                                        >
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                            <div class="d-flex justify-content-between">
                                <router-link
                                    to="/requirements-policy"
                                    class="mr-4 btn btn-sm btn-primary"
                                >
                                    Requirements
                                </router-link>
                                <router-link
                                    to="/"
                                    class="mr-4 btn btn-sm btn-default float-right"
                                >
                                    Dashboard
                                </router-link>
                            </div>
                        </div>
                        <div class="card-body pt-0 pt-md-4">
                            <div class="row">
                                <div class="col">
                                    <div class="card-profile-stats d-flex justify-content-center mt-md-5">

                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <input
                                    type="file"
                                    ref="photo"
                                    style="display: none"
                                    @change="onFileSelected"
                                >
                                <h3>
                                    <base-progress
                                        v-if="loadPercent.show"
                                        :type="loadPercent.type"
                                        :height="10"
                                        :value="loadPercent.value"
                                        :striped="true"
                                        :animated="loadPercent.animated"
                                    >
                                    </base-progress>
                                    <base-button
                                        size="sm" type="info"
                                        class="mr-4 marginLeft23"
                                        :disabled="lock"
                                        v-if="! loadPercent.show"
                                        @click="$refs.photo.click()"
                                    >
                                        <i class="fas fa-pen"></i>
                                        Click to change Logo
                                    </base-button>
                                </h3>
                                <h3 class="text-capitalize">
                                    {{student.full_name}}
                                </h3>
                                <span class="text-capitalize" v-if="student.school">
                                    {{ student.school.school_name ? student.school.school_name : 'N/A' }}
                                </span>
                                <div class="h5 mt-4">
                                    <badge size="sm"
                                        :type="student.approved_status == 'approved' ? 'success' : 'warning'"
                                      >
                                        {{ student.approved_status }}
                                      </badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br><br><br><br><br><br>

        <div class="container-fluid mt--7">
            <div class="row">

                <div class="col-xl-12 order-xl-1">

                    <card shadow type="secondary">
                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>
                    </card>

                    <card shadow type="secondary" v-if="! isLoadingBlade">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">
                                <div class="col-8">
                                    <h3 class="mb-0">Update Profile Information</h3>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                                </div>
                            </div>
                        </div>
                        
                        <template>
                            <tabs fill class="flex-column flex-md-row">
                                <card shadow>
                                    <tab-pane title="basicProfile">
                                        <span slot="title">
                                            <i class="ni ni-circle-08"></i>
                                            Basic Profile
                                        </span>
                                        <!-- import User Basic Profile template here -->
                                        <BasicProfile />
                                    </tab-pane>

                                    <!-- <tab-pane title="work">
                                        <span slot="title">
                                            <i class="ni ni-bell-55 mr-2"></i>
                                            Work/Specialization
                                        </span>
                                        <WorkProfile
                                            :loadUserProfile="loadUserProfile"
                                        />
                                    </tab-pane> -->

                                    <tab-pane title="Documents">
                                        <span slot="title">
                                            <i class="ni ni-archive-2"></i>
                                            Documents
                                        </span>
                                        <!-- import User Documents Profile template here -->
                                        <Document 
                                            :documents="studentProfile.documents"
                                            v-if="studentProfile && studentProfile.documents"
                                        />
                                    </tab-pane>
                                </card>
                            </tabs>

                        </template>


                    </card>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // get user's details from store
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert
    import BasicProfile from './UserProfile/BasicProfile.vue';
    import Document from './UserProfile/Document.vue';

    export default {
        components: {
            VuePhoneNumberInput,
            BasicProfile,
            Document
        },
        name: 'UserProfile',
        data() {
            return {
                studentProfile: {},
                savedEvent: null,
                showUploadButton: false,
                photo: {
                    id: null,
                    ext: null,
                    file: null
                },
                loadPercent: {
                    type: 'warning',
                    value: 10,
                    animated: true,
                    show: false
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                isloading: false,
                tel: {
                    phone: null,
                    code: 'NG'
                }
            }
        },
        computed: {
            ...mapGetters(['student', 'loadingBladeUrl', 'blankImageUrl']),
            studentPhoto() {// get MD Passport
                return this.student && this.student.photo_url ? this.student.photo_url : this.blankImageUrl;
            },
            lock() {
                return this.student && (this.student.profile_lock == 'yes')
            }
        },
        methods: {
            ...mapActions(['uploadUserPhoto', 'fetchStudentProfile', 'updatePhotoName', 'getLocations']),
            loadProfile() {
                this.fetchStudentProfile().then((res) => {
                    this.studentProfile = res;
                    this.isLoadingBlade = false;

                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    let msg = "Server Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            replaceByDefault(e) {
                return e.target.src = this.blankImageUrl;
            },
            makeUploadFieldsEmpty() {
                this.photo.id = null;
                this.photo.ext = null;
                this.photo.file = null;
            },
            onFileSelected(event) {
                if(! this.lock) {
                    this.savedEvent = null;
                    this.makeUploadFieldsEmpty();
                    this.savedEvent = event;
                    let status =  this.validateFile(event.target.files[0]);
                    return status ? this.uploadPhoto() : false;
                }                
            },
            uploadPhoto() {
                let event = this.savedEvent;// get store file event
                var fileReader = new FileReader();
                fileReader.readAsDataURL(event.target.files[0]);
                fileReader.onload = (event) => {// convert file to binary
                    this.photo.file = event.target.result;
                    let img = new Image();
                    img.src = window.URL.createObjectURL(this.savedEvent.target.files[0]);
                    img.onload = () => {
                        var height = img.height;
                        var width = img.width;
                        if ((width <= 500) && (height <= 500)) {
                            this.makeUploadApiCall();// upload photo
                        } else {
                            let msg = "Width and Height must not exceed 500 * 500 pixels";
                            swal.fire("Invalid Width/Height!", msg, "warning");
                            return false;
                        }
                    }
                }
            },
            makeUploadApiCall() {
                this.photo.id = this.$store.state.user.student.id;// get user id
                this.loadPercent.value = 10;
                this.loadPercent.show = true;
                this.uploadUserPhoto(this.photo).then((res) => {
                    this.uploadPercent(this.$store.state.user.uploadProgress);// show load progress
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    if(res.status) {
                        self.updatePhotoName(res.file_url);// update file name
                        setTimeout(function() {
                            self.loadPercent.show = false;
                        }, 3000);
                    } else {
                        swal.fire("Error Occurred!", "An error occurred while uploading, try again", "warning");
                        setTimeout(function() {
                            self.loadPercent.show = false;
                        }, 3000);
                    }
                }).
                catch(err => {
                    var self = this;
                    self.makeUploadFieldsEmpty();
                    self.savedEvent = null;
                    swal.fire(err.message+"!", "Reduce file size and try again...", "warning");
                    self.loadPercent.show = false;
                });
                this.fetchStudentProfile();// refresh page
            },
            uploadPercent(percent) {// update upload progress percent
                this.loadPercent.value = percent;
                this.loadPercent.type = percent > 60 ? 'success' : 'warning';
            },
            validateFile(file) {
                if(!file) { this.savedEvent = null; }// update seleted file
                const validFileTypes = ['jpg', 'jpeg', 'JPEG', 'JPG', 'png', 'PNG'];// valid image format array
                var fileExt = file.type.split('/').pop();// get selected file ext
                this.photo.ext = fileExt;
                var validExt = validFileTypes.includes(fileExt);
                let fileSize = file.size;// get selected file size
                if(validExt && fileSize <= 300000) {// for valid file and size
                    return true;
                } else if(! validExt) {// invalid file
                    this.savedEvent = null;
                    swal.fire("Invalid File!", "Only JPG/PNG files are accepted", "warning");
                    return false;
                } else if(fileSize > 300000) {// big file
                    this.savedEvent = null;
                    swal.fire("File is too large!", "File should be less than 300KB", "warning");
                    return false;
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadProfile();// get profile
            this.getLocations();
        }
    };
</script>
<style scoped>
    .width600 {
        width: 600px !important;
    }
    .marginLeft23 {
        margin-left: 23px;
    }
    .image-size {
        height: 180px;
        width: 180px;
    }
</style>
