<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <h3 class="mb-0 text-uppercase">Student Profile Information</h3>
                <hr class="my-4" />
            </div>

            <div class="col-lg-12 text-right">
                <img :src="photo" class="photo-class"/>
                <br><br>
            </div>

            <div class="table-responsive">

                <table class="table table-bordered">
                    <tbody>
                        <td>
                            <h4>RRR</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ application.transaction ? application.transaction.payment_ref : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>First Name</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.first_name ? profile.first_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Last Name</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.last_name ? profile.last_name : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Other Names</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.other_names ? profile.other_names : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Gender</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.gender ? profile.gender : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Email Address</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.email ? profile.email : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Phone No</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light">
                                {{ profile.phone ? profile.phone : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Student Type</h4>
                        </td>
                        <td>
                            <h4 class="font-weight-light text-capitalize">
                                {{ profile.type ? profile.type : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Admission Year</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.admission_year ? profile.admission_year : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Current Level</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.level ? profile.level : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>Matriculation Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.matric_no ? profile.matric_no : 'N/A' }}
                            </h4>
                        </td>
                        <td>
                            <h4>MDCN Index Number</h4>
                        </td>
                        <td>
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.index_no ? profile.index_no : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                    <tbody>
                        <td>
                            <h4>Student's School</h4>
                        </td>
                        <td colspan="7">
                            <h4 class="text-capitalize font-weight-light">
                                {{ profile.school && profile.school.school_name ? profile.school.school_name : 'N/A' }}
                            </h4>
                        </td>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-12">
                <hr class="my-4" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfileDetails',
        props: [ 'application' ],
        computed: {
            profile() {
                return this.application ? this.application.student : {};
            },
            photo() {// get MD passport
                return this.application && this.application.photo_url ? this.application.photo_url : this.blankImageUrl;
            },
            accreditation() {
                let records = this.application ? this.application.accreditation_type : false;
                if(records) {
                    let items = JSON.parse(records);
                    let text = '';
                    for (let index = 0; index < items.length; index++) {
                        const element = items[index];
                        text += element ? (index+1) + '. ' +element + '<br>' : '';
                    }
                    return text;
                } else {
                    return 'N/A';
                }
            },
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
    .photo-class {
        width: 200px;
        height: 200px;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>