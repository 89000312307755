<template>
    <div class="col-lg-12">
        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div
            class="col-lg-12"
            v-if="! showAddRecord && insuranceRecords && insuranceRecords.length > 0 && ! isLoadingBlade"
        >
            <div class="card shadow">
                <div class="table-responsive">
                    <base-table
                        class="table align-items-center table-flush"
                        thead-classes="thead-light"
                        tbody-classes="list"
                        :data="insuranceRecords"
                    >
                        <template slot="columns">
                            <th width="2%">S/N</th>
                            <th width="10%">Student' Name</th>
                            <th width="10%">Transaction No</th>
                            <th width="10%">Current Level</th>
                            <th width="10%">Status</th>
                            <th width="10%">Certificate No</th>
                            <th width="5%">&nbsp;</th>
                        </template>

                        <template
                            slot-scope="{row, index}"
                        >
                        <td class="budget">
                            {{ pagination.from+index}}
                        </td>
                        <td>
                            {{row && row.student && row.student.full_name ? row.student.full_name : 'N/A'}}
                        </td>
                        <td>{{(row.trans_no)}}</td>
                        <td>{{row.level ? row.level : 'N/A'}}</td>
                        <td>
                            <badge :type="showStatus(row.trans_status)">
                                {{ row.trans_status }}
                            </badge>
                        </td>
                        <td>
                            {{row.policy_no ? row.policy_no : 'N/A'}}
                        </td>
                        <td>
                            <a
                                :href="row.certificate_url"
                                target="_blank"
                                class="btn btn-success btn-sm text-white"
                                v-if="row.certificate_url"
                            >
                                <i class="fa fa-print"></i>
                                Print Certificate
                            </a>
                            
                            <base-button
                                type="primary" size="sm"
                                v-if="row.trans_status == 'pending' && (! isloading || (selectedRowNo != row.id))"
                                @click="$emit('reQueryTransaction', row)"
                            >
                                <i class="fa fa-history"></i> Re-Query 
                            </base-button>

                            <base-button
                                type="primary" size="sm"
                                v-if="isloading && (selectedRowNo == row.id)"
                            >
                                <span class="spinner-border spinner-border-sm"
                                    role="status" aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                                Please Wait...
                            </base-button>

                            <paystack
                                :amount="paystackInfo.amount"
                                :email="paystackInfo.email"
                                :paystackkey="paystackInfo.paystackkey"
                                :reference="row.trans_no"
                                :subaccount="paystackSubaccount"
                                :callback="callback"
                                :close="close"
                                :embed="false"
                                class="btn btn-info btn-sm"
                                v-if="((row.trans_status == 'failed') || (row.trans_status == 'pending'))
                                        && (! isloading || (selectedRowNo != row.trans_no))"
                            >
                                <i class="fa fa-credit-card"></i> Make Payment
                            </paystack>

                            
                        </td>

                        </template>

                    </base-table>
                </div>

                <div
                    class="card-footer d-flex justify-content-end bg-transparent"
                    v-if="hasPagination"
                     @click="paginate"
                >
                    <base-pagination
                        :total="pagination.total"
                        align="center"
                        v-model="pagination.number"
                        :perPage="pagination.perPage"

                    >
                    </base-pagination>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';
    import paystack from "vue-paystack";

    export default {
        name: 'InsuranceData',
        props: ["insuranceRecords", "showAddRecord", "isloading", "selectedRowNo", "isLoadingBlade", "pagination", "hasPagination", "paginate", "callback",
                "close", "paystackInfo"],
        components: {
            paystack
        },
        filters: {
            getDateFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY');
                } else {
                    return 'N/A';
                }
            },
            getDateTimeFormat(value) {
                if(value) {
                    return moment(value).format('Do MMM, YYYY [at] h:mm:ss a');
                } else {
                    return 'N/A';
                }
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'paystackSubaccount'])
        },
        methods: {
            showStatus(value) {
                if(value == 'paid') {
                    return 'success';
                } else if(value == 'PENDING') {
                    return 'warning';
                } else {
                    return 'danger';
                }
            }
        },
    }
</script>