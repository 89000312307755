<template>
    <div>
        <div v-if="! isLoadingBlade && ! showErrorStatus">
            <div class="row">

                <div class="col-lg-12">
                    <h3 class="mb-0 text-uppercase">Personal Information</h3>
                    <hr>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="RRR"
                        input-classes="form-control-alternative"
                        v-model="rrr"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Student Email Address"
                        placeholder="Enter Student Email Address"
                        input-classes="form-control-alternative"
                        v-model="record.email"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Title"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.title"
                            :disabled="lock"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="miss">Miss</option>
                            <option value="mr">Mr</option>
                            <option value="mrs">Mrs</option>
                            <option value="others">Others</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="Enter First Name"
                        input-classes="form-control-alternative"
                        v-model="record.first_name"
                        required
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Last Name"
                        placeholder="Enter Last Name"
                        input-classes="form-control-alternative"
                        v-model="record.last_name"
                        required
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Enter Other Names"
                        input-classes="form-control-alternative"
                        v-model="record.other_names"
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.gender"
                            :disabled="lock"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.marital_status"
                            :disabled="lock"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="divorced">Divorced</option>
                            <option value="married">Married</option>
                            <option value="single">Single</option>
                            <option value="widowed">Widowed</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input
                        label="Date of Birth"
                        addon-left-icon="ni ni-calendar-grid-58"
                        
                        required

                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: false, maxDate: 'today'}"
                            class="form-control datepicker"
                            v-model="record.date_of_birth"
                            :disabled="lock"
                        >
                        </flat-picker>
                    </base-input>
                </div>
             
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Student's Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Student Type"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.type"
                            :disabled="lock"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="medical">Medical</option>
                            <option value="dental">Dental</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-12">
                    <hr>
                    <h3 class="mb-0 text-uppercase">Contact (Residential) Information</h3>
                    <hr>
                </div>

                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Contact Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Contact Address"
                            v-model="record.contact_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Country"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.country" 
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"                           
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="State/Provice"
                        required
                    >
                        <autocomplete
                            ref="autocomplete"
                            :disableInput="cities && cities.length == 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State/Provice"
                            showNoResults
                            :source="cities"
                            :initialDisplay="record.state"
                            resultsValue="name"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Town"
                        placeholder="Enter Town"
                        input-classes="form-control-alternative"
                        v-model="record.town"
                        type="text"
                        required
                    />
                </div>

                <div class="col-lg-12">
                    <hr>
                    <h3 class="mb-0 text-uppercase">Academic History</h3>
                    <hr>
                </div>
                
                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Select Your University"
                        required
                    >
                        <autocomplete
                            v-if="schools && schools.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for University"
                            showNoResults
                            :source="schools"
                            v-model="record.world_school_id"
                            :disableInput="lock"
                            :initialDisplay="studentSchool"
                            :results-display="formattedDisplay"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Select Admission Year"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.admission_year"
                            :disabled="lock"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option 
                                v-for="year in years"
                                :key="year"
                                :value="year"
                            >
                                {{ year }}
                            </option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Current Level"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="record.level"
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="500">500</option>
                            <option value="600">600</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Matriculation Number"
                        placeholder="Enter Matriculation Number"
                        input-classes="form-control-alternative"
                        v-model="record.matric_no"
                        required
                        :readonly="lock"
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="MDCN Index Number"
                        placeholder="Enter MDCN Index Number"
                        input-classes="form-control-alternative"
                        v-model="record.index_no"
                        :readonly="lock"
                    />
                </div>
            </div>
        </div>

        <div class="col-lg-12" v-if="isLoadingBlade">
            <div class="d-flex justify-content-center">
                <img :src="loadingBladeUrl" />
            </div>
        </div>

        <div class="col-lg-12 text-center" v-if='(showErrorStatus || showInputErrorStatus) && ! isLoadingBlade'>
            <base-alert :type="errorType">
                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                <span class="alert-inner--text">
                    <h2 class="text-white">{{errorMsg}}</h2>
                </span>
            </base-alert>
        </div>

        <div class="col-lg-12" v-if="! isLoadingBlade">
            <hr>
            <base-button
                type="success"
                class="right"
                :disabled="! rrr"
                @click="savePersonalData()"
            >
                {{ isloading ? 'Please Wait...' : 'Save &amp; Continue'}}
                <i class="fa fa-arrow-right"></i>
            </base-button>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";    
    import swal from 'sweetalert2';// import sweet alert
    import moment from 'moment';
    import Autocomplete from 'vuejs-auto-complete';
    import states from './../../../../states.json';

    export default {
        name: 'ProfileData',
        props: ['displayPhoto', 'displayInsurance', 'data', 'transaction', 'loadStudentInfo', 'id', 'lock'],
        components: {
            VuePhoneNumberInput,
            Autocomplete,
            flatPicker
        },
        data() {
            return {
                isLoadingBlade: true,
                isloading: false,
                showInputErrorStatus: false,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                record: {},
                tel: {
                    phone: null,
                    code: 'NG',
                },
                states: states,
                cities: []
            }
        },
        computed: {
            ...mapGetters(['loadingBladeUrl', 'locations']),
            rrr() {
                return this.transaction  ? this.transaction.payment_ref : null;
            },
            years() {
                let startYear = 1959;// start from 1900
                let currentYear = parseInt(moment().format('YYYY'));
                let years = [];
                for (let i = currentYear; i > startYear; i-- ) {
                    years.push(i);
                }
                return years;
            },
            schools() {// get all nigerian schools
                return this.data && this.data.schools ? this.data.schools : [];
            },
            studentSchool() {// get student's school name
                return this.data && this.data.student && this.data.student.school && this.data.student.school.school_name 
                            ? this.data.student.school.school_name : '';
            },
            hasCountry() {// student country of residence 
                return this.data && this.data.student && this.data.student.residence_country ? this.data.student.residence_country : {};
            }
        },
        methods: {
            ...mapActions(['updateStudentData', 'getLocations']),
            setProfileData() {// set applicant data
                this.showInputErrorStatus = false;
                this.emptyFields();
                this.isLoadingBlade = false;
                if(this.data) {
                    let application = this.data;
                    let student = application.student;
                    this.record = {
                        email:              student.email,
                        title:              student.title,
                        first_name:         student.first_name,
                        last_name:          student.last_name,
                        other_names:        student.other_names,
                        gender:             student.gender,
                        marital_status:     student.marital_status,
                        date_of_birth:      student.date_of_birth,                        
                        phone:              student.phone,
                        phone_code:         student.phone_code,
                        type:               student.type,
                        contact_address:    student.contact_address,
                        country:            student.country,
                        state:              student.state,
                        town:               student.town,
                        world_school_id:    student.world_school_id,
                        admission_year:     student.admission_year,
                        level:              student.level,
                        matric_no:          student.matric_no,
                        index_no:           student.index_no
                    };
                    student.residence_country ? this.changeCountry(false, student.residence_country) : false;// update country
                    this.loadPhoneNumber();// update phone number
                }
            },
            loadPhoneNumber() {// update phone no
                let data = this.record.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            changeCountry(e, country = null) {// update cities 
                this.cities = [];
                let data = e ? e.selectedObject : country;
                this.cities = this.states.filter(item => item.country_code == data.code);
            },
            changeCity(e) {// update state
                this.record.state = null;
                let data = e.selectedObject;
                return this.record.state = data.name;
            },
            savePersonalData() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                let res = this.validatedData();// valid input data
                if(res.status) {// update
                    let data = { profile_data : res.data, id : this.id, option : false };
                    this.updateStudentData(data).then((res) => {
                        this.isloading = false;
                        this.loadStudentInfo();
                        if(res.status) {
                            this.displayInsurance(true);
                            swal.fire("Profile Updated!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                } else {
                    this.isloading = false;
                    swal.close();
                }
            },
            validatedData() {// check if input is a locked one and is valid before passing
                const inputs = this.record; // get all data
                delete inputs.email;// delete email it can't be changed 
                const lockedInputs = ['first_name', 'last_name', 'other_names', 'gender', 'marital_status', 'date_of_birth', 'type', 'world_school_id', 
                                        'admission_year', 'matric_no', 'index_no'];// inputs that can't be updated when locked
                const optionalInputs = ['other_names', 'index_no' ];// get optional inputs
                const inputsArray = Object.entries(inputs);
                let validItems = {};
                for(let item of inputsArray) {
                    const input = item[0];
                    const value = item[1];
                    const isLocked = lockedInputs.includes(input) && this.lock;// check if an input is locked
                    const isValid = (value != null && value != '');// check for empty input
                    const isOptionalInput = optionalInputs.includes(input);
                    if(! isLocked && (isValid || isOptionalInput)) {// push item into valid items
                        validItems[input] = value;
                    } else if(! isValid && ! isOptionalInput) {
                        const errorText = (input == 'world_school_id') ? 'University' : this.replaceAll(input, '_', ' ');
                        this.showInuptError(errorText);
                        validItems = {};
                        break;
                    }                    
                }
                var status = Object.entries(validItems).length > 0;
                return {data : validItems, status : status };
            },
            titleCase(string){
                return string[0].toUpperCase() + string.slice(1).toLowerCase();
            },
            escapeRegExp(string) {
                return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
            },
            replaceAll(str, find, replace) {
                return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
            },
            emptyFields() {
                this.record = {
                    first_name:         null,
                    last_name:          null,
                    other_names:        null,
                    phone:              null,
                    phone_code:         null,
                    type:               null,
                    world_school_id:    null,
                    admission_year:     null,
                    matric_no:          null,
                    index_no:           null,
                    gender:             null,
                    level:              null,
                    title:              null,
                    marital_status:     null,
                    date_of_birth:      null,
                    contact_address:    null,
                    town:               null,
                    state:              null,
                    country:            null
                };
            },
            showInuptError(name) {
                this.isloading = false;
                this.showInputErrorStatus = true;
                this.errorType = 'warning'
                this.errorMsg = 'Invalid ' + this.titleCase(name) + ' has been entered';
                var self = this;
                setTimeout(function() { self.showInputErrorStatus = false; }, 5000);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            formattedDisplay (result) {
                return result.school_name + ' [' + result.city + ']';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.setProfileData();
            this.getLocations();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right !important;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>