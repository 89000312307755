<template>
    <form @submit.prevent="saveProfileData">
        <h6 class="heading-small text-muted mb-4">User information</h6>

        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Title"
                        placeholder="Title"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.title"
                        required
                        readonly
                    />
                </div>
                <div class="col-lg-3">
                    <base-input alternative=""
                        label="First Name"
                        placeholder="First Name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.first_name"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Last Name"
                        placeholder="Last Name"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.last_name"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Other Names"
                        placeholder="Other Names"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.other_names"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Gender"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.gender"
                            disabled
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Marital Status"
                        required
                    >
                        <select
                            class="form-control form-control-alternative"
                            v-model="profile.marital_status"
                            disabled
                        >
                            <option :value="null" disabled selected>--- Select One ---</option>
                            <option value="divorced">Divorced</option>
                            <option value="married">Married</option>
                            <option value="single">Single</option>
                            <option value="widowed">Widowed</option>
                        </select>
                    </base-input>
                </div>

                <div class="col-lg-3">
                    <base-input alternative=""
                        label="Email Address"
                        placeholder="Email Address"
                        input-classes="form-control-alternative"
                        v-model="profile.email"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-3">
                    <base-input
                        label="Date of Birth"
                        addon-left-icon="ni ni-calendar-grid-58"
                        required
                    >
                        <flat-picker
                            slot-scope="{focus, blur}"
                            @on-open="focus"
                            @on-close="blur"
                            :config="{allowInput: false, maxDate: 'today'}"
                            class="form-control datepicker"
                            v-model="profile.date_of_birth"
                            disabled
                        >
                        </flat-picker>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Phone Number"
                        placeholder="Phone Number"
                        input-classes="form-control-alternative"
                        required
                    >
                    <!-- v-model="profile.phone"  -->
                        <VuePhoneNumberInput
                            v-model="tel.phone"
                            @update="updatePhoneNumber"
                            :default-country-code="tel.code"
                            :no-example="true"
                            :clearable="true"
                        />
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Matriculation Number"
                        placeholder="Matriculation Number"
                        input-classes="form-control-alternative"
                        v-model="profile.matric_no"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Student Type"
                        placeholder="Student Type"
                        input-classes="form-control-alternative text-capitalize"
                        v-model="profile.type"
                        required
                        readonly
                    />
                </div>              
            </div>
        </div>

        <hr>
        <h6 class="heading-small text-muted mb-4">Contact information</h6>

        <div class="pl-lg-4">
            <div class="row">
                <div class="col-lg-12">
                    <base-input alternative=""
                        label="Contact Address"
                        required
                    >
                        <textarea
                            rows="2"
                            class="form-control form-control-alternative"
                            placeholder="Enter Contact Address"
                            v-model="record.contact_address"
                        ></textarea>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Country"
                        required
                    >
                        <autocomplete
                            v-if="locations.countries && locations.countries.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for Country"
                            showNoResults
                            :source="locations.countries"
                            v-model="record.country" 
                            :initialDisplay="(hasCountry ? hasCountry.name : '')"                           
                            @selected="changeCountry"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="State/Provice"
                        required
                    >
                        <autocomplete
                            ref="autocomplete"
                            v-if="cities && cities.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search State/Provice"
                            showNoResults
                            :source="cities"
                            :initialDisplay="record.state"
                            resultsValue="name"
                            @selected="changeCity"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Town"
                        placeholder="Enter Town"
                        input-classes="form-control-alternative"
                        v-model="record.town"
                        type="text"
                        required
                    />
                </div>
            </div>
        </div>

        <hr>
        <h6 class="heading-small text-muted mb-4">Academy History</h6>

        <div class="pl-lg-4">
            <div class="row">

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Select Your University"
                        required
                    >
                        <autocomplete
                            v-if="schools && schools.length > 0"
                            input-class="form-control form-control-alternative"
                            placeholder="Type to Search for University"
                            showNoResults
                            :source="schools"
                            v-model="profile.world_school_id"
                            :disableInput="lock"
                            :initialDisplay="studentSchool"
                            :results-display="formattedDisplay"
                        >
                        </autocomplete>
                    </base-input>
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Adminssion Year"
                        placeholder="Adminssion Year"
                        input-classes="form-control-alternative"
                        v-model="profile.admission_year"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-4">
                    <base-input alternative=""
                        label="Current Level"
                        placeholder="Current Level"
                        input-classes="form-control-alternative"
                        v-model="profile.level"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="Matriculation Number"
                        placeholder="Enter Matriculation Number"
                        input-classes="form-control-alternative"
                        v-model="profile.matric_no"
                        required
                        readonly
                    />
                </div>

                <div class="col-lg-6">
                    <base-input alternative=""
                        label="MDCN Index Number"
                        placeholder="Enter MDCN Index Number"
                        input-classes="form-control-alternative"
                        v-model="profile.index_no"
                        readonly
                    />
                </div>
            </div>
        </div>



        <base-alert :type="errorType" dismissible v-if='showErrorStatus'>
            <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
            <span class="alert-inner--text">
                {{errorMsg}}
            </span>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </base-alert>

        <hr>

        <div class="pl-lg-4">
            <base-button type="success" @click="saveProfileData()">
                <i class="fa fa-save" @if='! isloading'></i>
                {{isloading ? 'Please Wait' : 'Save Changes'}}
            </base-button>
        </div>
    </form>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import flatPicker from "vue-flatpickr-component";
    import "flatpickr/dist/flatpickr.css";  
    import swal from 'sweetalert2';// import sweet alert
    import states from '../../../states.json';
    import Autocomplete from 'vuejs-auto-complete';

    export default {
        components: {
            VuePhoneNumberInput,
            flatPicker,
            Autocomplete
        },
        name: 'BasicProfile',
        data() {
            return {
                profile: {},
                record: {
                    phone: null,
                    phone_code: null,
                    contact_address: null,
                    country : null,
                    state : null,
                    town : null
                },
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                isloading: false,
                tel: {
                    phone: null,
                    code: 'NG'
                },
                states: states,
                cities: []
            }
        },
        computed: {
            ...mapGetters(['student', 'locations']),
            lock() {
                return this.student && (this.student.profile_lock == 'yes')
            },
            hasCountry() {// student country of residence 
                return this.student && this.student.residence_country ? this.student.residence_country : {};
            },
            studentSchool() {// get student's school name
                return this.student && this.student.school && this.student.school.school_name ? this.student.school.school_name : '';
            },
            schools() {// get all nigerian schools
                return this.student && this.student.schools ? this.student.schools : [];
            }
        },
        methods: {
            ...mapActions(['fetchStudentProfile', 'updateStudentData']),
            loadPhoneNumber(student) {// update phone no
                let data = student.phone_code;
                let exploded = data ? data.split('-') : false;
                this.tel.code = exploded && exploded[0] ? exploded[0] : 'NG';
                this.tel.phone = exploded && exploded[1] ? exploded[1] : null;
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.record.phone = event.formattedNumber;
                    this.record.phone_code = event.countryCode+'-'+event.phoneNumber;
                }
            },
            saveProfileData() {
                if(this.student) {
                    this.isloading = true;
                    this.preloader();// show loading
                    let data = { profile_data : this.record , id : this.student.id, option : true };
                    this.updateStudentData(data).then((res) => {
                        this.loadProfile();
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Profile Updated!", res.msg, "success");
                        } else {
                            let msg = "Error occurred while updating, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }                
            },
            loadProfile() {
                this.fetchStudentProfile().then((res) => {
                    this.profile = {// uneditable data
                        title :             res.title,
                        first_name:         res.first_name,
                        last_name:          res.last_name,
                        other_names:        res.other_names,
                        email:              res.email,                        
                        matric_no:          res.matric_no,
                        admission_year:     res.admission_year,
                        type:               res.type,
                        gender:             res.gender,
                        marital_status:     res.marital_status,
                        date_of_birth :     res.date_of_birth,
                        world_school_id:    res.world_school_id,
                        level:              res.level,
                        matric_no:          res.matric_no,
                        index_no:           res.index_no
                    };
                    this.record = {// editable data
                        phone:              res.phone,
                        phone_code:         res.phone_code,
                        contact_address :   res.contact_address,
                        country :           res.country,
                        state :             res.state,
                        town :              res.town
                    };
                    res.residence_country ? this.changeCountry(false, res.residence_country) : false;// update country
                    this.loadPhoneNumber(res);
                    this.isLoadingBlade = false;
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    let msg = "Server Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                });
            },
            changeCountry(e, country = null) {// update cities 
                this.cities = [];
                let data = e ? e.selectedObject : country;
                this.cities = this.states.filter(item => item.country_code == data.code);
            },
            changeCity(e) {// update state
                this.record.state = null;
                let data = e.selectedObject;
                return this.record.state = data.name;
            },
            formattedDisplay (result) {
                return result.school_name + ' [' + result.city + ']';
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadProfile();// get profile
        },
    }
</script>

<style>
    .form-control-label {
        width: 100%;
    }
</style>