<template>
    <div>
        <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center no-padding-bottom">
            <span class="mask bg-gradient-danger opacity-8"></span>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-12 order-xl-1">
                    <br><br><br>
                    <card shadow type="secondary">
                        <div slot="header" class="bg-white border-0">
                            <div class="row align-items-center">

                                <div class="col-8">
                                    <h3 class="mb-0 text-uppercase text-danger" v-html="title"></h3>
                                    <br>
                                    <span class="text-danger" v-html="description"></span>
                                </div>
                                <div class="col-4 text-right">
                                    <router-link
                                        to="/"
                                        class="btn btn-danger"
                                    >
                                        <i class="fa fa-reply"></i> 
                                        Back
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div v-if="! isVisitation && ! isSubmitted && ! isLoadingBlade">
                            <!-- this is hospital's profile template -->
                            <ProfileData
                                :id="application.id"
                                :data="application"
                                :transaction="application.transaction"
                                :loadStudentInfo="loadStudentInfo"
                                :displayInsurance="displayInsurance"
                                :lock="true"
                                v-if="showProfileData && application && application.student && ! isLoadingBlade"
                            />


                            <Insurance 
                                :id="application.id"
                                :record="application"
                                :loadStudentInfo="loadStudentInfo"
                                :displayProfileData="displayProfileData"
                                :displayPhoto="displayPhoto"
                                v-if="showInsurance && application && ! isLoadingBlade"
                            />
                            

                            <!-- Import Profile Photo template here --> 
                            <StudentPhoto
                                :id="application.student.id"
                                :application="application"
                                :loadStudentInfo="loadStudentInfo"
                                :displayInsurance="displayInsurance"
                                :displayDocuments="displayDocuments"
                                :lock="true"
                                v-if="showPhoto && application && application.student && ! isLoadingBlade"
                            />

                            <!-- this is cpd's documents -->
                            <Documents
                                :application="application"
                                :documents="application.documents"
                                :loadStudentInfo="loadStudentInfo"
                                :displayPhoto="displayPhoto"
                                :displaySummary="displaySummary"
                                :lock="true"
                                v-if="showDocuments && application && application.documents && ! isLoadingBlade"
                            />

                            <!-- this is application summary template -->
                            <VerificationSummary
                                :application="application"
                                :displayDocuments="displayDocuments"
                                :loadStudentInfo="loadStudentInfo"
                                v-if="showSummary && application && ! isLoadingBlade"
                            />
                        </div>

                        <div class="col-lg-12" v-if="isSubmitted && ! isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="completeImageUrl" />
                            </div>
                            <br>
                            <h2 class="text-uppercase text-center">
                                Your Visitation application was submitted succesfully... 
                            </h2>
                            <h4 class="text-uppercase text-center text-danger">
                                Note: You will be contacted subsequently by MDCN, when the date of visitation is fixed
                            </h4>
                            <hr>
                            <div class="text-center">
                                <router-link
                                    to="/"
                                    class="btn btn-lg btn-success"
                                >
                                    <i class="fa fa-home"></i>
                                    Dashboard Now
                                </router-link>
                            </div>
                            <br><br>
                        </div>

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div class="col-lg-12 text-center" v-if='showErrorStatus && ! isLoadingBlade'>
                            <base-alert :type="errorType">
                                <!-- <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span> -->
                                <span class="alert-inner--text">
                                    <h2 class="text-white">{{errorMsg}}</h2>
                                </span>
                            </base-alert>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import Insurance from '../../Verification/Application/Insurance/Insurance.vue';
    import ProfileData from '../../Verification/Application/ProfileData';
    import StudentPhoto from '../../Verification/Application/StudentPhoto.vue';
    import Documents from '../../Verification/Application/Documents.vue';
    import VerificationSummary from '../../Verification/Application/VerificationSummary/VerificationSummary';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        name: 'EditApplication',
        components: {
            Insurance,
            ProfileData,
            StudentPhoto,
            Documents,
            VerificationSummary
        },
        data() {
            return {
                application: null,
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isLoadingBlade: true,
                showInsurance: false,
                showProfileData: true,
                showSummary: false,
                showPhoto: false,
                showDocuments: false,
                code: this.$route.name,
                title: null,
                description: null,
                id: this.$route.params.id,
                isloading: false,
                isSubmitted: false
            }
        },
        computed: {
            ...mapGetters(['websiteUrl', 'loadingBladeUrl', 'completeImageUrl']),
            isVisitation() {// check if it is visitation application
                return this.application && (this.application.type == "visitation");
            }
        },
        methods: {
            ...mapActions(['getApplication', 'submitCpdApplication']),
            loadTitle(application) {// load title
                let title = '';
                title += this.replaceString(this.code);
                title += application && application.indexing_year ? ' For ' + application.indexing_year : '';
                return this.title = title;
            },
            replaceString(value) {
                return value ? value.replace('-', ' ') : 'Application Form';
            },
            changeTitle(value, des, status) {// change title
                let description = des ? des : 'Kindly fill the form below with correct information...';
                if(status) {
                    this.title = value;
                    this.description = description;
                } else {
                    this.loadTitle();
                }
            },
            loadStudentInfo() {
                this.isLoadingBlade = true;
                this.application = null;
                if(this.id) {
                    this.getApplication(this.id).then((res) => {                        
                        if(res.status) {
                            this.loadTitle(res.application);
                            this.application = res.application;
                            if(res.application && (res.application.status != 'rejected') && (res.application.status != 'school-rejected') 
                                    && (res.application.status != 'pending')) {// check if rejected
                                return window.location.href = '/';
                            } else {
                                return this.isLoadingBlade = false;
                            }
                        } else {
                            let msg = 'Payment has not been made, proceed to make payment'
                            swal.fire("Payment Failed!", msg, "warning");
                            setTimeout(function() { window.location.href = '/applications'; }, 3000);
                        }
                    }).
                    catch(() => {
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    let msg = 'An error occured, kindly try again...'
                    swal.fire("Error Occurred!", msg, "warning");
                    setTimeout(function() { window.location.href = '/applications'; }, 3000);
                }
            },
            displayInsurance(value) {
                this.showInsurance = value;
                this.showProfileData = ! value;
                this.showSummary = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
            },
            displayProfileData(value) {
                this.showProfileData = value;
                this.showInsurance = ! value;
                this.showSummary = ! value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
            },
            displayPhoto(value) {
                this.showInsurance = ! value;
                this.showProfileData = ! value;
                this.showSummary = ! value;
                this.showPhoto = value;
                this.showDocuments = ! value;
            },
            displayDocuments(value) {
                this.showInsurance = ! value;
                this.showProfileData = ! value;
                this.showSummary = ! value;
                this.showPhoto = ! value;
                this.showDocuments = value;
            },
            displaySummary(value) {
                this.showInsurance = ! value;
                this.showProfileData = ! value;
                this.showSummary = value;
                this.showPhoto = ! value;
                this.showDocuments = ! value;
            },
            submitNow() {// submit application
                swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Proceed!'
                }).then((result) => {
                    if (result.value) {
                        this.makeSubmitApiCall();// make api call
                    }
                });
            },
            makeSubmitApiCall() {
                this.isloading = true;
                this.showInputErrorStatus = false;
                this.preloader();// show loading
                if(this.application.id) {// submit
                    this.submitCpdApplication(this.application.id).then((res) => {
                        this.isloading = false;
                        if(res.status) {
                            swal.fire("Application Submitted!", res.msg, "success");
                            this.isSubmitted = true;
                            // setTimeout(function() { window.location.href = '/applications'; }, 1000); 330641368475
                        } else {
                            let msg = "An error occurred while submitting form, reload page";
                            swal.fire("Error Ocurred!", msg, "warning");
                        }
                    }).
                    catch(() => {
                        this.isloading = false;
                        let msg = "Server Failure!, kindly reload the page...";
                        swal.fire("Error Ocurred!", msg, "warning");
                    });
                }   else {
                    this.isloading = false;
                    let msg = "Server Failure!, kindly reload the page...";
                    swal.fire("Error Ocurred!", msg, "warning");
                }
            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadStudentInfo();
        }
    }
</script>

<style scoped>
    .no-padding-bottom {
        padding-bottom: 0rem !important
    }
    .right {
        float: right;
    }
</style>

<style>
    .form-control-label {
        width: 100%;
    }
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
</style>