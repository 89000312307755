<template>
    <div>
        <base-header type="gradient-danger" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->



            <div class="row" v-if="! isLoadingBlade && student">
                <div class="col-lg-12" v-if="dashboardInfo && dashboardInfo.reject_status && dashboardInfo.reject_status.status">
                  <router-link :to="dashboardInfo.reject_status.url">
                    <marquee-text :duration="30" class="text-dark">
                       Note: Your Application was rejected, kindly click here for more information.......
                    </marquee-text>
                  </router-link>
                  <br><br>
                </div>

                <div class="col-xl-4 col-lg-6"
                  v-if="dashboardInfo && dashboardInfo.reject_status && ! dashboardInfo.reject_status.status"
                >
                    <stats-card title="MDCN Index Number"
                                :type="'gradient-'+dashboardInfo.index_status.color"
                                :sub-title="dashboardInfo.index_status.title"
                                icon="ni ni-satisfied"
                                class="mb-4 mb-xl-0 text-uppercase"
                                :class="dashboardInfo.index_status.color"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                        <span class="text-nowrap"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6"
                    v-if="dashboardInfo && dashboardInfo.reject_status && dashboardInfo.reject_status.status"
                >
                    <stats-card title="Application Status"
                                :type="'gradient-'+dashboardInfo.reject_status.color"
                                :sub-title="dashboardInfo.reject_status.title"
                                icon="ni ni-circle-08"
                                class="mb-4 mb-xl-0 text-uppercase"
                                :class="dashboardInfo.reject_status.color"
                    >

                        <template slot="footer">
                            <span class="text-success mr-2 text-capitalize">
                              <i class="fa fa-arrow-right"></i> Next Action:</span>
                            <span class="text-nowrap">
                              <router-link
                                  :to="dashboardInfo.reject_status.url"
                                  class="text-dark text-capitalize"
                              >
                                {{ dashboardInfo.reject_status.url_title }}
                              </router-link>
                            </span>
                        </template>
                    </stats-card>
                </div>


                <div class="col-xl-4 col-lg-6" v-if="dashboardInfo && dashboardInfo.insurance_status">
                    <stats-card title="Insurance Status"
                                :type="'gradient-'+dashboardInfo.insurance_status.color"
                                :sub-title="dashboardInfo.insurance_status.title"
                                icon="ni ni-credit-card"
                                class="mb-4 mb-xl-0 dark text-uppercase"
                                :class="dashboardInfo.insurance_status.color"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                        <span class="text-nowrap"></span>
                      </template>
                    </stats-card>
                </div>

                <div class="col-xl-4 col-lg-6" v-if="dashboardInfo && dashboardInfo.clearance_status">
                    <stats-card title="Yearly Clearance Status"
                                :type="'gradient-'+dashboardInfo.clearance_status.color"
                                :sub-title="dashboardInfo.clearance_status.title"
                                icon="ni ni-satisfied"
                                class="mb-4 mb-xl-0 text-uppercase"
                                :class="dashboardInfo.clearance_status.color"
                    >
                      <template slot="footer">
                        <span class="text-success mr-2"></span>
                        <span class="text-nowrap"></span>
                      </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7 padding30">
            <div class="row">
                <div class="col-xl-12 order-xl-2 mb-5 mb-xl-0">

                    <div class="card card-profile shadow">

                        <div class="col-lg-12" v-if="isLoadingBlade">
                            <div class="d-flex justify-content-center">
                                <img :src="loadingBladeUrl" />
                            </div>
                        </div>

                        <div v-if="! isLoadingBlade && student">
                            <div class="row justify-content-center">
                              <div class="col-lg-3 order-lg-2">
                                  <div class="card-profile-image">
                                      <router-link to="/profile">
                                          <img
                                            :alt="student.full_name"
                                            :src="photo"
                                            @error="replaceByDefault"
                                            class="rounded-circle image"
                                          >
                                      </router-link>
                                  </div>
                              </div>
                          </div>
                          <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                              <div class="d-flex justify-content-between">
                                  <!-- <a
                                    href="/user-guide.pdf" 
                                    target="_blank"
                                    class="mr-4 btn btn-sm btn-primary"
                                  >
                                    User Guide
                                  </a> -->
                                  <router-link
                                    to="/requirements-policy"
                                    class="mr-4 btn btn-sm btn-default float-right text-uppercase"
                                  >
                                  Requirements 
                                  </router-link>
                              </div>
                          </div>
                          <div class="card-body pt-0 pt-md-4" v-if="student">
                              <div class="row">
                                  <div class="col">
                                      <div class="card-profile-stats d-flex justify-content-center mt-md-5">
                                      </div>
                                  </div>
                              </div>
                              <div class="text-center">
                                  <h2 class="text-capitalize">
                                      {{ student.full_name }}
                                  </h2>
                                  <h3 class="text-capitalize">
                                      <badge size="sm"
                                        :type="student.approved_status == 'approved' ? 'success' : 'warning'"
                                      >
                                        {{ student.approved_status }}
                                      </badge>
                                  </h3>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Email Address
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ student.email }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Phone Number
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ student.phone }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Student Type
                                        <badge size="sm" type="success" class="text-capitalize">
                                          {{ student.type }}
                                        </badge>
                                  </div>
                                  <div class="h5 mt-4 fontSize15">
                                      <i class="ni business_briefcase-24 mr-2"></i>
                                        Matriculation Number
                                        <badge size="sm" type="success" class="text-lowercase">
                                          {{ student.matric_no }}
                                        </badge>
                                  </div>
                              </div>
                          </div>

                          <!-- Other Notice template here -->
                          <!-- <div class="col-md-4" v-if="visitModel">
                            <modal :show="visitModel"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Pay Visitation Fee Now!</h4>
                                    <p>
                                      <strong>Note:</strong> Your Application has been approved,
                                      kindly click the button below to pay the required visitation fee now...
                                    </p>
                                </div>

                                <template slot="footer">
                                  <router-link
                                    to="/visitation-payment"
                                    class="mr-4 btn btn-lg btn-white"
                                  >
                                    Pay Now
                                  </router-link>
                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="visitModel = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                          </div> -->

                          <!-- Reject template here -->
                          <!-- <div class="col-md-4" v-if="rejectModal">
                            <modal :show="rejectModal"
                                  gradient="danger"
                                  modal-classes="modal-danger modal-dialog-centered">
                                <h4
                                  slot="header" class="modal-title"
                                  id="modal-title-notification"
                                >
                                  Importance Notice
                                </h4>

                                <div class="py-3 text-center">
                                    <i class="ni ni-bell-55 ni-3x"></i>
                                    <h4 class="heading mt-4">Application Rejected!</h4>
                                    <p>
                                      <strong>Note:</strong> Your Application was rejected,
                                      kindly click the button below to make corrections and re-submit...
                                    </p>
                                </div>

                                <template slot="footer">
                                  <router-link
                                    :to="'/edit-application/'+ rejectId"
                                    class="mr-4 btn btn-lg btn-white"
                                    v-if="rejectId"
                                  >
                                    Click Here
                                  </router-link>

                                  
                                    <base-button type="link"
                                                text-color="white"
                                                class="ml-auto"
                                                @click="rejectModal = false">
                                        Close
                                    </base-button>
                                </template>
                            </modal>
                          </div> -->

                      </div>
                    </div>
                </div>
            </div>
            <!-- End charts-->
        </div>
    </div>
</template>
<script>
  // get user's details from store
  import { mapGetters, mapActions } from 'vuex';
  import moment from 'moment';
  import MarqueeText from 'vue-marquee-text-component';

  export default {
    name: 'Dashboard',
    components: { MarqueeText },
    filters: {
        getDateFormat(value) {
            if(value) {
              return moment(value).format('Do MMM, YYYY');
            } else {
              return 'N/A';
            }
        }
    },
    computed: {
      ...mapGetters(['student', 'loadingBladeUrl', 'blankImageUrl', 'cpdPhotoUrl']),
      dashboardInfo() {
        let clearance = this.student && this.student.clearance_status;
        let insurance = this.student && this.student.insurance_status;
        let rejected = this.student && this.student.rejected_app;
        let index = this.student && this.student.index_no;
        return {          
          clearance_status : {
            color : (clearance ? 'green' : 'red'),
            title : (clearance ? 'Cleared' : 'Waiting Approval')
          },
          insurance_status : {
            color : (insurance ? 'green' : 'red'),
            title : (insurance ? 'Valid' : 'Expired')
          },
          reject_status : {
            status : (rejected ? true : false),
            color : 'red',
            title : 'Application Rejected',
            url : '/application-details/' + (rejected ? rejected.id : ''),
            url_title : 'Click Here'
          },
          index_status : {
            color : (index ? 'green' : 'red'),
            title : (index ? index : 'N/A')
          },
        };
      },
      photo() {// get MD Passport
        return this.student && this.student.photo_url ? this.student.photo_url : this.blankImageUrl;
      },
      rejectId() {
        return this.student.reject_status || this.student.reject_visit_status; 
      }
    },
    data() {
      return {
        modal: false,
        isLoadingBlade: true,
        visitModel: false,
        rejectModal: false
      };
    },
    methods: {
      ...mapActions(['fetchStudentProfile']),
      stopLoading() {
        var self = this;
        if(this.student) {
          setTimeout(function() {
            // self.visitModel = (self.cpd.visitation_status && ! self.cpd.visit_pay_status) ? true : false;
            // self.rejectModal = (self.cpd.reject_status || self.cpd.reject_visit_status) ? true : false;
            self.isLoadingBlade = false;
          }, 1000);
        }
      },
      replaceString(value) {
          if(value) {
            return value.replace("-", " ");
          } else {
            return 'N/A';
          }
      },
      replaceByDefault(e) {
          return e.target.src = this.blankImageUrl;
      }
    },
    mounted() {
      this.fetchStudentProfile();// get profile
      this.stopLoading();
    }
  };
</script>
<style scoped>
  .padding30 {
    padding: 30px;
  }
  .fontSize15 {
    font-size: 15px;
  }
  .red {
    background-color: #efd2d2;
  }
  .green {
    background-color: #bcf9c6;
  }
  .yellow {
    background-color: #e8eb4b;
  }
  .left {
    float: left;
  }
  .image {
    width: 170px;
    height: 170px;
  }
</style>

<style>
  .modal-footer {
    margin-top: -50px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.25rem;
    border-top: 0 solid #e9ecef;
    border-bottom-right-radius: 0.4375rem;
    border-bottom-left-radius: 0.4375rem;
  }
</style>
