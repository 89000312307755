<template>
    <form>
        <h6 class="heading-small text-muted mb-4">
            Upload Files &nbsp;&nbsp;&nbsp;&nbsp;
            <span class="text-danger">
                <i class="fa fa-info-circle"></i> Only PDF Files (Not More than 500KB) are supported for each file
            </span>            
        </h6>

        <div
            class="col-sm-4 text-center borderLine"
            v-show="documents && documents.length > 0"
            v-for="(document, index) in documents"
            :key="index"
        >
            <br>
            <input
                type="file"
                id="file" style="display: none"
            >
            <h4 class="text-capitalize height-50">
                <span>{{ document.name }}</span><br><br>
                <em class="title-font text-danger">
                    {{ document.description }}
                </em>
            </h4><br><br><br>
            <base-button
                tag="a" type="primary"
                size="sm"
                :href="document.url"
                class="active" role="button"
                aria-pressed="true"
                target="_blank"
                v-if="document.hasFile"
            >
                <i class="fa fa-eye"></i> View File
            </base-button>
            <!-- <badge
                type="danger"
                v-if="! document.hasFile"
            >
                No File Uploaded
            </badge> -->
            <br><br>
        </div>
    </form>
</template>

<script>

    export default {
        name: 'Document',
        props: ['documents']
    }
</script>

<style scoped>
    .height-50 {
        height: 50px;
    }
    .borderLine {
        border: 1px solid #ededed;
    }
    .description-size {
        font-size: 11px;
    }
</style>
