<template>
    <div class="row align-items-center">

        <div class="col-8">
            <h4 class="heading-small text-muted mb-4">
                Below are your insurance indemnity records:
                <br>
                <span class="text-danger">
                    Confirm if your transaction actually went through before attempting to make another payment, 
                    you can click on <em class="text-primary">Re-Query button</em> below
                    <br>
                    Note that you can't proceed without valid Insurance...
                </span>
            </h4>
        </div>
        <div class="col-4 text-right">
            <base-button
                class="float-right"
                :type="showAddRecord ? 'danger' : 'success'"
                size="lg"
                @click="displayNewRecord()"
                :disabled="hasPendingInsurance"
            >
                <i class="fa fa-plus-circle" v-if="! showAddRecord"></i>
                <i class="fa fa-times" v-if="showAddRecord"></i>
                {{ showAddRecord ? 'Cancel Purchase' : 'Purchase Insurance' }}
            </base-button>
        </div>


        <InsurancePurchaseForm
            :data="record"
            :loadStudentInfo="loadStudentInfo"
            :hasValidInsurance="hasValidInsurance"
            :paystackInfo="paystackInfo"
            :callback="insuranceCallback"
            :close="insuranceClose"
            :loadInsuranceRecords="loadInsuranceRecords"
            v-if="showAddRecord && ! printCertificate"
        />

        <!-- import edu records details template here -->
        <InsuranceData
            :insuranceRecords="insuranceRecords"
            :showAddRecord="showAddRecord"
            :isLoadingBlade="isLoadingBlade"
            :pagination="pagination"
            :hasPagination="hasPagination"
            :isloading="isloading"
            :selectedRowNo="selectedRowNo"
            :paginate="paginate"
            :callback="insuranceCallback"
            :close="insuranceClose"
            :paystackInfo="paystackInfo"
            v-on:reQueryTransaction="reQueryTransaction"
            v-if="! printCertificate"
        />

        <div class="col-lg-12" v-if="printCertificate">
            <div class="d-flex justify-content-center">
                <img :src="completeImageUrl" />
            </div>
            <br>
            <h2 class="text-uppercase text-center">
                Your Insurance certificate is ready for printing....
            </h2>
            <h4 class="text-uppercase text-center text-danger">
                Note: You can click on the button below to print your certificate...
            </h4>
            <hr>
            <div class="text-center">
                <a
                    :href="certificateUrl"
                    target="_blank"
                    class="btn btn-success text-white"
                >
                    <i class="fa fa-print"></i>
                    Print Certificate
                </a>
            </div>
            <br><br>
        </div>

        <div class="col-lg-12 text-center" v-if='showErrorStatus'>
            <br>
            <base-alert :type="errorType">
                <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
                <span class="alert-inner--text">
                    {{errorMsg}}
                </span>
            </base-alert>
        </div>

        

        <div class="col-lg-12">
            <hr class="my-4"/>
            <base-button
                type="danger"
                @click="showPreviousPage()"
            >
                <i class="fa fa-arrow-left"></i>
                Previous
            </base-button>
            <div class="right" v-if="hasValidInsurance || printCertificate">
                <base-button
                    type="success"
                    @click="showNextPage()"
                >
                    Continue
                    <i class="fa fa-arrow-right"></i>
                </base-button>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import InsuranceData from './InsuranceData.vue';
    import InsurancePurchaseForm from './InsurancePurchaseForm.vue';
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import swal from 'sweetalert2';// import sweet alert

    export default {
        components: {
            InsuranceData,
            InsurancePurchaseForm,
            VuePhoneNumberInput
        },
        name: 'Insurance',
        props: ['record', 'displayProfileData', 'displayPhoto', 'loadStudentInfo'],
        data() {
            return {
                insuranceRecords: [],
                paginatedItems: [],
                pagination: {
                    total: null,
                    prev_page_url: null,
                    next_page_url: null,
                    current_page: null,
                    last_page: null,
                    number: 1,
                    from: 0,
                    to: null,
                    perPage: this.$store.state.user.perPage // get per page count
                },                
                showErrorStatus: false,
                errorType: null,
                errorMsg: null,
                isloading: false,
                selectedRowNo: null,
                showAddRecord: false,
                showInputErrorStatus: false,
                isLoadingBlade: true,
                printCertificate: false,
                certificateUrl: null
            };
        },
        computed: {
            ...mapGetters(['paystackkey', 'completeImageUrl']),
            hasPagination() {
                return this.paginatedItems && (this.paginatedItems.length > this.pagination.perPage)
                    ? true : false;
            },
            student() {
                return  this.record && this.record.student ? this.record.student : {};
            },
            hasValidInsurance() {// check or valid insurance status
                return this.student && this.student.insurance_status  && (this.student.insurance_status == 'valid');
            },
            hasPendingInsurance() {// check for pending insurance 
                return this.student && this.student.pending_insurance_status;
            },
            paystackInfo() {
                let amount = this.record && this.record.insurance_type && this.record.insurance_type.has_payment 
                                        && this.record.insurance_type.has_payment.amount ? this.record.insurance_type.has_payment.amount : false;
                let email = this.student && this.student.email ? this.student.email : false;
                return { amount: (amount * 100), email: email, paystackkey: this.paystackkey };
            }
        },
        methods: {
            ...mapActions(['getStudentInsuranceRecords', 'reQueryInsurance', 'addUserInsurance', 'deleteUserEduRecord', 'updateInsuranceRef', 
                            'approvedInsurancePayment']),
            showPreviousPage() {// go to next page
                return this.displayProfileData(true);
            },
            showNextPage() {// go to next page
                return this.displayPhoto(true);
            },
            updatePhoneNumber(event) {
                if(event && event.isValid && event.e164) {
                    this.records.beneficiary_phone = event.formattedNumber;
                }
            },
            emptyInsuranceRecordsMsg() {
                return this.insuranceRecords && this.insuranceRecords.length == 0 && (!this.showAddRecord)
                    ? this.showEmptyMsg() : false;
            },
            showEmptyMsg() {// show empty msg
                this.showErrorStatus = true;
                this.errorType = 'warning';
                this.errorMsg = 'No Insurance Records Yet!, kindly purchase one';
            },
            loadInsuranceRecords() {// get insurance records
                this.getStudentInsuranceRecords(this.pagination.perPage).then((res) => {
                    this.setPages(res.records);
                }).
                catch(() => {
                    this.showErrorStatus = true;
                    this.isLoadingBlade = false;
                    this.errorMsg = "Server Failure!", "Kindly reload the page...";
                    this.errorType = 'danger';
                });
            },
            setPages(res) {// set paginated function
                this.insuranceRecords = [];
                this.paginatedItems = res.data;
                this.insuranceRecords = this.paginatedItems;
                this.pagination.from = res.from;
                this.pagination.to = res.to;
                this.pagination.total = res.total;
                this.pagination.current_page = res.current_page;
                this.pagination.last_page = res.last_page;
                this.pagination.prev_page_url = res.prev_page_url;
                this.pagination.next_page_url = res.next_page_url;
                this.isLoadingBlade = false;
                this.emptyInsuranceRecordsMsg();//show empty msg
            },
            paginate() {// paginate function
                this.showErrorStatus = false;
                let url = (this.pagination.number > this.pagination.current_page) ?
                    this.pagination.next_page_url : this.pagination.prev_page_url;
                if(url) {// make api call
                    this.isLoadingBlade = true
                    this.getPaginatedApplicationItems(url).then((res) => {// get users
                        this.pagination.number = res.records.current_page;
                        this.setPages(res.records);
                    }).
                    catch(() => {
                        this.users = [];
                        this.showErrorStatus = true;
                        this.isLoadingBlade = false;
                        this.errorMsg = "Server Failure!, Kindly reload the page...";
                        this.errorType = 'danger';
                    });
                } else {
                    this.insuranceRecords = this.paginatedItems;
                }
            },
            displayNewRecord() {// show new record
                if(this.insuranceRecords && this.insuranceRecords.length == 0) {// hide empty msg
                    this.showEmptyMsg();
                    this.showErrorStatus = this.showAddRecord;
                }
                return this.showAddRecord = ! this.showAddRecord;
            },
            reQueryTransaction(record) {// re-query payment function
                this.isloading = true;
                this.selectedRowNo = record.id;
                this.approvedInsurancePayment({ ref : record.trans_no}).then((res) => {// add insurance
                        this.isloading = false;
                        this.selectedRowNo = null;
                        if(res.status) {
                            swal.fire('Payment Successful', res.msg, 'success');
                            this.printCertificate = true;
                            this.certificateUrl = res.url;
                        } else {
                            swal.fire('Error Occurred!', res.msg, 'warning');
                            this.loadInsuranceRecords();
                        }                    
                    }).
                    catch(() => {
                        this.isloading = false;
                        this.selectedRowNo = null;
                        swal.fire('Error Occurred!', 'An error occurred while updating, try again', 'warning');
                        this.loadInsuranceRecords();
                    });
            },
            insuranceCallback(response) {
                this.printCertificate = false;
                this.certificateUrl = null;
                if(response.status == 'success') {
                    this.preloader();
                    this.approvedInsurancePayment({ ref : response.reference}).then((res) => {// add insurance
                        this.isloading = false;
                        if(res.status) {
                            swal.fire('Payment Successful', res.msg, 'success');
                            this.printCertificate = true;
                            this.certificateUrl = res.url;
                        } else {
                            swal.fire('Error Occurred!', res.msg, 'warning');
                            this.loadInsuranceRecords();
                        }                    
                    }).
                    catch(() => {
                        this.isloading = false;
                        swal.fire('Error Occurred!', 'An error occurred while updating, try again', 'warning');
                        this.loadInsuranceRecords();
                    });
                } else {
                    swal.fire('Payment Failed!', 'An error occurred while proccessing, try again', 'warning');
                }
            },
            insuranceClose() {
                this.preloader();
                this.updateInsuranceRef({}).then((res) => {// add insurance
                    this.isloading = false;
                    if(res.status) {
                        swal.fire('Transaction Ref Updated', 'Try and make payment again....', 'warning');
                    } else {
                        swal.fire('Error Occurred!', 'An error occurred while verifying, try again', 'warning');
                    }   
                    this.loadInsuranceRecords();                 
                }).
                catch(() => {
                    this.isloading = false;
                    swal.fire('Error Occurred!', 'An error occurred while updating, try again', 'warning');
                    this.loadInsuranceRecords();
                });

            },
            preloader() {
                return  swal.fire({
                    title: 'Please Wait...',
                    html: 'Trying to fetch data from <b>server</b>',
                    // timer: 2000,
                    timerProgressBar: true,
                    onBeforeOpen: () => {
                        swal.showLoading()
                    },
                });
            }
        },
        created() {
            this.loadInsuranceRecords();// load educational records
        },
    }
</script>

<style scoped>
    .float-right {
        float: right;
    }
</style>